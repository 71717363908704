// header data

// service img
import service1 from "../../assets/images/service-1.jpg";
import service2 from "../../assets/images/service-2.jpg";
import service3 from "../../assets/images/service-3.jpg";
import service4 from "../../assets/images/service-4.jpg";
import services1 from '../../assets/images/services/Property-Consulting.png'
import services2 from '../../assets/images/services/interior-design-contracting.png'
import services3 from '../../assets/images/services/office-maintanance-&-indoor-live-planr-rental-service.png'
import services4 from '../../assets/images/services/stationary-services.png'

//   new properties image
import slider1 from "../../assets/images/slider/1.jpg";
import slider2 from "../../assets/images/slider/2.jpg";
import slider3 from "../../assets/images/slider/3.jpg";
import slider4 from "../../assets/images/slider/4.jpg";
import slider5 from "../../assets/images/slider/5.jpg";
import slider6 from "../../assets/images/slider/6.jpg";
import slider7 from "../../assets/images/slider/7.jpg";
import slider8 from "../../assets/images/slider/8.jpg";
import slider9 from "../../assets/images/slider/10.jpg";

// sale properties image

import newSlider1 from "../../assets/images/newSlider1.jpg";
import newSlider2 from "../../assets/images/newSlider2.jpg";
import newSlider3 from "../../assets/images/newSlider3.jpg";

// filter pages images
import img1 from "../../assets/images/recentSearch.jpg";
import img2 from "../../assets/images/recentSearch1.jpg";
import img3 from "../../assets/images/recentSearch2.jpg";
import img4 from "../../assets/images/recentSearch3.jpg";
import img5 from "../../assets/images/recentSearch4.jpg";

// description page icon

import { MdOutlineFastfood } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { MdOutlineManageAccounts } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { SiUps } from "react-icons/si";
import { FiServer } from "react-icons/fi";
import { CgGames } from "react-icons/cg";
import { GrYoga } from "react-icons/gr";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { GiDiscussion } from "react-icons/gi";
import { HiBuildingStorefront } from "react-icons/hi2";
import { MdOutlineFoodBank } from "react-icons/md";
import { CgGym } from "react-icons/cg";
import { FaServer } from "react-icons/fa";
import { RiBuilding2Fill } from "react-icons/ri";
import { GiSparkPlug } from "react-icons/gi";
import { HiOutlineCurrencyRupee } from "react-icons/hi";

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const settings2 = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  // autoplaySpeed:200,
  cssEase: "linear",
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ourClientSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  autoplay: true,
  dotsClass: 'slick-dots',
  // autoplaySpeed:200,
  cssEase: "linear",
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots:true,
      },
    },
  ],
};
export const blogSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  autoplay: true,
  dotsClass: 'slick-dots',
  // autoplaySpeed:200,
  cssEase: "linear",
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots:false,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots:false,
      },
    },
  ],
};

// header Data
export const headerDatas = [
  {
    id: 1,
    navDropDownLInk: " Warehouse for rent in Chennai ",
    path: "/filter2?workspaceType=warehouse&type=Rent&location=undefined&district=Chennai",
  },
  {
    id: 2,
    navDropDownLInk: "Warehouse for sale in Chennai ",
    path: "/filter2?workspaceType=warehouse&type=Sale&location=undefined&district=Chennai",
  },
  {
    id: 3,
    navDropDownLInk: "Office for rent in IT park Chennai",
    path: "/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai",
  },
  {
    id: 4,
    navDropDownLInk: " 100 Seater office for rent in Chennai ",
    path: "/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai",
  },
  {
    id: 5,
    navDropDownLInk: " 200 Seater office for rent in Chennai ",
    path: "/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai",
  },
  {
    id: 6,
    navDropDownLInk: " Office for rent in Chennai ",
    path: "/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai",
  },
  {
    id: 7,
    navDropDownLInk: " Rental income property for sale in Chennai ",
    path: "/filter2?workspaceType=undefined&type=Sale&location=undefined&district=Chennai",
  },
];

export const services = [
  {
    id: 1,
    serviceType: " PROPERTY CONSULTING",
    imgSrc: services1,
    alt: "service-img",
  },
  {
    id: 2,
    serviceType: " INTERIOR DESIGN CONTRACTING",
    imgSrc: services2,
    alt: "service-img",
  },
  {
    id: 3,
    serviceType: " OFFICE MAINTENANCE & INDOOR LIVE PLANT RENTAL SERVICES",
    imgSrc: services3,
    alt: "service-img",
  },
  {
    id: 4,
    serviceType: " STATIONARY SERVICE",
    imgSrc: services4,
    alt: "service-img",
  },
];

export const newProperties = [
  {
    id: 1,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider1,
  },
  {
    id: 2,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider2,
  },
  {
    id: 3,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider3,
  },
  {
    id: 4,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider4,
  },
  {
    id: 5,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider5,
  },
  {
    id: 6,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider6,
  },
  {
    id: 7,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider7,
  },
  {
    id: 8,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider8,
  },
  {
    id: 9,
    title: "1300 sqft 100 seat furshed office at ambattur",
    imgSrc: slider9,
  },
];

export const saleProperties = [
  {
    id: 1,
    saleImg: newSlider1,
    title: "2000 sqft 25 seat 4 cabin Furnished",
    location: "Chennai, Ambattur",
    price: "INR 4000000",
    propertyType: "Commercial",
    sqft: 11335,
  },
  {
    id: 2,
    saleImg: newSlider2,
    location: "Chennai, Thambaram ",
    title: "3000 sqft 40 seat 6 cabin Furnished",
    price: "INR 6000000",
    propertyType: "Co-working",
    sqft: 12337,
  },
  {
    id: 3,
    saleImg: newSlider3,
    location: "Chennai, Guindy",
    title: "5000 sqft 80 seat 8 cabin Furnished",
    price: "INR 10000000",
    propertyType: "Commercial",
    sqft: 15735,
  },
  {
    id: 4,
    saleImg: newSlider1,
    location: "Chennai, Guindy",
    title: "5000 sqft 80 seat 8 cabin Furnished",
    price: "INR 10000000",
    propertyType: "Commercial",
    sqft: 15735,
  },
];

// filter data
export const cardDatas = [
  {
    id: 1,
    img: img1,
    title: "1800 Sqft 30 Seater Furnished Office",
    location: "Perungudi, Chennai",
    price: 400000,
  },
  {
    id: 2,
    img: img2,
    title: "5000 sqft 80 seat 8 cabin Furnished",
    location: "Guindy, Chennai",
    price: 458000,
  },
  {
    id: 3,
    img: img3,
    title: "3000 sqft 40 seat 6 cabin Furnished",
    location: "Thambaram, Chennai",
    price: 307000,
  },
  {
    id: 4,
    img: img4,
    title: "1800 Sqft 30 Seater Furnished Office",
    location: "Kolathur, Chennai",
    price: 650000,
  },
  {
    id: 5,
    img: img5,
    title: "1800 Sqft 30 Seater Furnished Office",
    location: "Kolathur, Chennai",
    price: 750000,
  },
  {
    id: 6,
    img: img2,
    title: "200 Seater office for rent in Chennai",
    location: "Kolathur, Chennai",
    price: 650000,
  },
  {
    id: 7,
    img: img1,
    title: "1800 Sqft 30 Seater Furnished Office",
    location: "Kolathur, Chennai",
    price: 650000,
  },
  {
    id: 8,
    img: img3,
    title: "3000 sqft 40 seat 6 cabin Furnished",
    location: "Kolathur, Chennai",
    price: 650000,
  },
];

// Description page

// interior facility data

export const InteriorFacility = [
  {
    id: 1,
    icon: <GiDiscussion />,
    title: "Reception",
  },
  {
    id: 2,
    icon: <MdOutlineMeetingRoom />,
    title: "Meeting room",
  },
  {
    id: 3,
    icon: <SiGoogleclassroom />,
    title: "Conference room",
  },
  {
    id: 4,
    icon: <MdOutlineManageAccounts />,
    title: "4 manager cabin",
  },
  {
    id: 5,
    icon: <GrWorkshop />,
    title: " 95 modular workstations",
  },
  {
    id: 6,
    icon: <SiUps />,
    title: "UPS room",
  },
  {
    id: 7,
    icon: <FiServer />,
    title: "Server room",
  },
  {
    id: 8,
    icon: <MdOutlineFastfood />,
    title: "Pantry",
  },
];

// comman facility data

export const commanFacility = [
  {
    id: 1,
    icon: <HiBuildingStorefront />,
    title: "Auditorium",
  },
  {
    id: 2,
    icon: <MdOutlineFoodBank />,
    title: "Food court",
  },
  {
    id: 3,
    icon: <CgGym />,
    title: "Gym",
  },
  {
    id: 4,
    icon: <CgGames />,
    title: "Indoor games",
  },
  {
    id: 5,
    icon: <GrYoga />,
    title: "Yoga center",
  },
  {
    id: 6,
    icon: <GiSparkPlug />,
    title: " Outdoor gardening and landscape",
  },
  {
    id: 7,
    icon: <MdOutlineFastfood />,
    title: "Pantry",
  },
  {
    id: 8,
    icon: <FaServer />,
    title: "Server room",
  },
  {
    id: 9,
    icon: <RiBuilding2Fill />,
    title: "Plug & play office space suits for IT / ITES companies.",
  },
];

// commercial data
export const commercial = [
  {
    id: 1,
    icon: <HiOutlineCurrencyRupee />,
    title: "Rent  :  Rs. 65 / sqft",
  },
  {
    id: 2,
    icon: <HiOutlineCurrencyRupee />,
    title: "Maintenance :  Rs. 9.5 / sqft",
  },
];

//  filtter page data
export const FeaturedListing = [
  {
    id: 1,
    img: img1,
    location: "Ambattur , Chennai",
    sqft: "4800 sqft",
  },
  {
    id: 2,
    img: img3,
    location: "Guindy , Chennai",
    sqft: "3200 sqft",
  },
  {
    id: 3,
    img: img4,
    location: "Thambaram , Chennai",
    sqft: "5100 sqft",
  },
];

export const RecentSearch = [
  {
    id: 1,
    img: img2,
    location: "Red Hills , Chennai",
    sqft: "2500 sqft",
  },
  {
    id: 2,
    img: img1,
    location: "T.Nagar , Chennai",
    sqft: "3900 sqft",
  },
  {
    id: 3,
    img: img5,
    location: "Ambattur , Chennai",
    sqft: "4800 sqft",
  },
];

export const sqftOptions = [
  {
    label: '1000 to 2000Sqft',    
    value: '1000 to 2000Sqft',
    min:1000,
    max:2000,
    // value: ["1000","2000"],
  },
  {
    label: '2000 to 3000Sqft',
    value: '2000 to 3000Sqft',
    min:2000,
    max:3000,
    // value: ["2000","3000"],
  },
  {
    label: '3000 to 4000Sqft',
    value: '3000 to 4000Sqft',
    min:3000,
    max:4000,
    // value: ["3000","4000"],
  },
  {
    label: '4000 to 6000Sqft',
    value: '4000 to 6000Sqft',
    min:4000,
    max:6000,
    // value: ["4000","6000"],
  },
  {
    label: '5000 to 10000Sqft',
    value: '5000 to 10000Sqft',
    min:5000,
    max:10000,
    // value: ["5000","10000"],
  },
  {
    label: '10000 to 15000Sqft',
    value: '10000 to 15000Sqft',
    min:10000,
    max:15000,
    // value: ["10000","15000"],
  },
  {
    label: '15000 to 20000Sqft',
    value: '15000 to 20000Sqft',
    min:15000,
    max:20000,
    // value: ["15000","20000"],
  },
  {
    label: '20000Sqft Above',
    value: '20000Sqft Above',
    min:20000,
    max:100000,
    // value: ["20000",""],
  },
]
export const budgetOptions = [
  {
    label: 'Upto 3,00,000',    
    value: 'Upto 3,00,000', 
    min:1000,
    max:300000,
  },
  {
    label: '10,00,000 to 50,00,000',
    value: '10,00,000 to 50,00,000',
    min:1000000,
    max:5000000,
  },
  {
    label: '50,00,000 to 100,00,000',
    value: '50,00,000 to 100,00,000', 
    min:5000000,
    max:10000000,
  },
  {
    label: '100,00,000 to 500,00,000',
    value: '100,00,000 to 500,00,000',
    min:10000000,
    max:50000000,
  },
  {
    label: '500,00,000 to 1000,00,000',
    value: '500,00,000 to 1000,00,000',
    min:50000000,
    max:100000000,
  },
  {
    label: '1000,00,000 to 3000,00,000',
    value: '1000,00,000 to 3000,00,000',
    min:100000000,
    max:300000000,
  },
  {
    label: 'Over 7000,00,000',
    value: 'Over 7000,00,000',
    min:700000000,
    max:1000000000,
  },
]