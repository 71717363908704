import React from "react";

function removeSpace(data) {
  let text = data;
  let tempresult = text.replace(/^\s+|\s+$/gm, "");
  var result = tempresult.replace(/\s+/g, '-').toLowerCase();
  return result;
}

export default removeSpace;
