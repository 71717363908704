import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// sass
import "./Description.scss";

// Bootstarp
import Container from "react-bootstrap/Container";

// carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Loader from "./../../components/Loader/Loader";

// import { InteriorFacility } from "./../../components/data/DataHome";

import { FaUsersCog } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";
// social icons
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { ImPinterest2 } from "react-icons/im";

// images
import parse from "html-react-parser";

import { useDispatch } from "react-redux";

// formick
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getPropertieDetailById } from "./../../store/properties";
import { getProfileDetails } from "./../../store/profile";
import { commanFacility } from "./../../components/data/DataHome";
import { getInteriorFacility } from "../../components/common/interiorFacility";
import { getCommonFacility } from "../../components/common/commonFacility";

let initialValues = {
  name: null,
  email: null,
  phone: null,
  message: null,
  propertyName: null,
};

const onSubmit = (values) => {
  console.log("form data", values);
};

const imgURL = process.env.REACT_APP_IMAGE_URL;

const phoneRegExp = /^[0-9]{10}$/;
const nameRegExp = /^[A-Za-z]+$/;

const validationSchema = Yup.object({
  propertyName: Yup.string(),
  name: Yup.string()
    .matches(nameRegExp, "Invalid characters in name")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Required !"),
  email: Yup.string().email("Invalid email format!").required("Required !"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required !"),
  message: Yup.string(),
});

const Innerpage = () => {
  const params = useParams();
  const [propertie, setPropertie] = useState([]);
  const [loading, setLoading] = useState(true);

  // const sliceItem = items.slice(0, 1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPropertieDetailById(params.id)).then((res) => {
      initialValues.propertyName = res[0].data?.buildingName;
      setPropertie(res[0].data);
      setLoading(false);
    });

    dispatch(getProfileDetails()).then((res) => {
      console.log("res ", res);
    });
  }, [dispatch]);

  const showDescription = (data) => {
    return parse(data);
  };

  const handlePageClick = (data) => {
    console.log(data.selected);

    // let currentPage = data.selected + 1;

    // const commentsFromServer = await fetchComments(currentPage);

    // setItems(commentsFromServer);
  };

  return (
    <>
      <div className="innerpage-wrapper">
        {!loading ? (
          <Container className="innerPage py-3">
            <div className="innerPage__section1 d-flex">
              <div className="section1__left">
                <div className="title py-2">
                  <h4>{propertie?.title}</h4>
                </div>
                <div className="carousel">
                  <Carousel showArrows={true}>
                    {propertie?.images.map((res) => {
                      return (
                        <div>
                          <img src={`${imgURL}${res}`} alt={res} />
                        </div>
                      );
                    })}
                  </Carousel>
                  <div className="social-icons d-flex justify-content-between">
                    <button>
                      <FiFacebook /> Facebook
                    </button>
                    <button>
                      <FiTwitter /> Twitter
                    </button>
                    <button>
                      <ImPinterest2 /> Pinterst
                    </button>
                    <button>
                      <BsEnvelope />
                      Email
                    </button>
                  </div>
                </div>

                <div className="innerPage__section2">
                  {propertie?.propertyDescription &&
                    showDescription(propertie.propertyDescription)}
                  <div className="details d-grid">
                    <div className="details__title">
                      {propertie?.amenities && (
                        <>
                          <strong>Interior facility :</strong>
                          <ul>
                            {propertie.amenities.map((res) => {
                              return getInteriorFacility(res);
                            })}
                          </ul>
                        </>
                      )}
                    </div>
                    <div className="details__title">
                      <strong>Common facility :</strong>
                      <ul>
                        {propertie.amenities.map((res) => {
                          return getCommonFacility(res);
                        })}
                      </ul>
                    </div>
                    <div className="details__title">
                      <strong>Commercial :</strong>
                      <ul className="commercial">
                        <li key="1">
                          <strong>Title:</strong> {propertie?.title}
                        </li>
                        <li key="2">
                          <strong>Property Type:</strong>{" "}
                          {propertie?.propertyType}
                        </li>
                        <li key="3">
                          <strong>Ranking:</strong> {propertie?.ranking}
                        </li>
                        <li key="4">
                          <strong>Featured:</strong> {propertie?.isFeatured}
                        </li>
                        <li key="5">
                          <strong>Property Facing:</strong>{" "}
                          {propertie?.propertyFacing}
                        </li>
                        <li key="6">
                          <strong>Work Space Type:</strong>{" "}
                          {propertie?.workspaceType}
                        </li>
                        <li key="7">
                          <strong>Building Name:</strong>{" "}
                          {propertie?.buildingName}
                        </li>
                        <li key="8">
                          <strong>Latitude:</strong> {propertie?.latitude}
                        </li>
                        <li key="9">
                          <strong>Longitude:</strong> {propertie?.longitude}
                        </li>
                        <li key="10">
                          <strong>Total Area:</strong> {propertie?.totalArea}
                        </li>
                        <li key="11">
                          <strong>Ownership Structure:</strong>{" "}
                          {propertie?.ownershipStructure}
                        </li>
                        <li key="12">
                          <strong>Developer Landlord Name:</strong>
                          {propertie?.developerLandlordName}
                        </li>
                        {/*  <li key="1"><strong>
                      Property Description:</strong> {propertie?.propertyDescription}
                    </li> */}
                        <li key="13">
                          <strong>MicroMarket:</strong> {propertie?.microMarket}
                        </li>
                        {/*  <li key="1"><strong>{propertie?.amenities}</li> */}
                        <li key="14">
                          <strong>PerSqft / Rate:</strong>{" "}
                          {propertie?.perSqftRate}
                        </li>
                        <li key="15">
                          <strong>Rental Value:</strong> {propertie?.rentalValue}
                        </li>
                        <li key="16">
                          <strong>Floor Description:</strong>{" "}
                          {propertie?.floorDescription}
                        </li>
                        <li key="17">
                          <strong>Year Built:</strong> {propertie?.yearBuilt}
                        </li>
                        <li key="18">
                          <strong>Storey:</strong> {propertie?.storey}
                        </li>
                        <li key="19">
                          <strong>Typical Floor Area:</strong>{" "}
                          {propertie?.typicalFloorArea}
                        </li>
                        <li key="20">
                          <strong>Parking Ratio:</strong>{" "}
                          {propertie?.parkingRatio}
                        </li>
                        <li key="21">
                          <strong>Power Backup:</strong> {propertie?.powerBackup}
                        </li>
                        <li key="22">
                          <strong>Air Conditioning:</strong>{" "}
                          {propertie?.airConditioning}
                        </li>
                        <li key="23">
                          <strong>Transport:</strong> {propertie?.transport}
                        </li>
                        <li key="24">
                          <strong>Nearest Parking:</strong>{" "}
                          {propertie?.nearestParking}
                        </li>
                        <li key="25">
                          <strong>Facilities:</strong> {propertie?.facilities}
                        </li>
                        <li key="26">
                          <strong>Internal Parking:</strong>{" "}
                          {propertie?.internalParking}
                        </li>
                        <li key="27">
                          <strong>Nearby Amenities:</strong>{" "}
                          {propertie?.nearbyAmenities}
                        </li>
                        <li key="28">
                          <strong>Address:</strong>
                          {propertie?.locality &&
                            `${propertie?.locality?.locationName}, ${propertie?.locality?.district}, ${propertie?.locality?.state}: ${propertie?.locality?.pincode}`}
                        </li>
                      </ul>
                    </div>
                    <div className="details__title">
                      <strong>
                        Please feel free to contact for more details and site
                        visit.
                      </strong>
                      <ul>
                        <li key="31">
                          <FaUsersCog /> Gopi Krishnan
                        </li>
                        <li key="32">
                          <FaUserFriends /> Property Consultant
                        </li>
                        <li key="33">
                          <ImOffice /> Chennai Commercials
                        </li>
                        <li key="34">
                          <BsFillTelephoneFill /> 91717 66660
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section1__right">
                <div className="title py-2">
                  <h4>Request More Information</h4>
                  <p>
                    Your email address will not be published. Required fields
                    are marked <span>*</span>{" "}
                  </p>
                </div>
                <div className="innerPage__form">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <div className="form-control py-2">
                        <label htmlFor="property">Info</label>
                        <Field disabled type="text" name="propertyName" />
                      </div>

                      <div className="form-control">
                        <label htmlFor="name">
                          Name <span>*</span>
                        </label>
                        <Field type="text" name="name" id="name" />
                        <ErrorMessage name="name">
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="form-control">
                        <label htmlFor="email">
                          Email <span>*</span>{" "}
                        </label>
                        <Field type="email" name="email" id="email" />
                        <ErrorMessage name="email">
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="form-control">
                        <label htmlFor="phone">
                          Phone <span>*</span>{" "}
                        </label>
                        <Field name="phone" id="phone" />
                        <ErrorMessage name="phone">
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="form-control">
                        <label htmlFor="message">
                          Message <span>*</span>{" "}
                        </label>
                        <Field id="message" name="message" as="textarea" />
                        <ErrorMessage name="message">
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="from-control">
                        <button type="submit" onSubmit={onSubmit}>
                          submit
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <div className="gmap-wrapper">
                  <iframe
                    title="google map location"
                    src="https://maps.google.com/maps?t=m&amp;q=loc:13.007401038484872,80.20478171164&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Innerpage;
