import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink } from "react-router-dom";

// SASS
import "./Header.scss";

// Data
import { headerDatas } from "./../data/DataHome";

// images
import Logo from "./../../assets/images/cc-logo.png";
import FinalLogo from "../../assets/images/Final logo.jpg"
import FinalLogo2 from "../../assets/images/Final logo 2.jpg"

// icons
import { MdOutlineArrowDropDown } from "react-icons/md";

const Header = () => {
  // sticky navbar
  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2 ");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <>
      <div className={header}>
        <div className="header__navbar">
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand to="/">
                <Link to="/">
                  {" "}
                  <img src={Logo} alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"  />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                  <NavLink as={Link} to="/" className="lineHeight" >
                    Home
                  </NavLink>
                  <NavLink as={Link} to="/filter2?workspaceType=warehouse&type=Rent&location=undefined&district=Chennai" className="lineHeight" >
                    IT Parks in Chennai
                  </NavLink>
                  <NavLink as={Link} to="/filter2" className="lineHeight" >
                    Properties For Sale
                  </NavLink>
                  <Nav className="hover-dropdown-head lineHeight">
                    Properties <MdOutlineArrowDropDown />
                    <ul className="hover-dropdown">
                      {headerDatas.map((headerData) => {
                        return (
                          <div key={headerData.id}>
                            <NavLink
                              as={Link}
                              to={headerData.path}
                              className="hover-dropdown-link"
                            >
                              {headerData.navDropDownLInk}
                            </NavLink>
                          </div>
                        );
                      })}
                    </ul>
                  </Nav>

                  {/* mobile nav drop-down */}

                  <NavDropdown title="Properties" id="basic-nav-dropdown">
                    {headerDatas.map((headerData) => {
                      return (
                        <NavDropdown.Item key={headerData.id}>
                          <NavLink as={Link} to={headerData.path} >
                            {headerData.navDropDownLInk}
                          </NavLink>
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>

                  <NavLink as={Link} to="/contact" className="lineHeight" >
                    Contact Us
                  </NavLink>
                </Nav>
                <NavLink as={Link} to="/thankyou" className="enquiery" >
                  Get a Free Quote
                </NavLink>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
