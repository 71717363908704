import React, { useEffect } from "react";
import styled from "styled-components";
import lottie from "lottie-web";
import RipplrLogo from "./../assets/images/cc-logo.png";
import NoNetwork from "./../assets/NoNetwork.json";

const Content = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export default function OfflinePage() {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#noNetwork"),
      animationData: NoNetwork,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return (
    <div minHeight="100vh">
      <div margin="auto">
        <div>
          <img src={RipplrLogo} alt="Ripplr Cartoon" />
        </div>
        <div padding="2rem 0">
          <Content>Seems like you are offline &#128577;</Content>
          <Content>Please check your network</Content>
        </div>
        <div id="noNetwork"></div>
      </div>
    </div>
  );
}
