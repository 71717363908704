import { capitalize } from "@mui/material";
import { CgGames, CgGym } from "react-icons/cg";
import { FaServer } from "react-icons/fa";
import { GiSparkPlug } from "react-icons/gi";
import { GrYoga } from "react-icons/gr";
import { HiBuildingStorefront } from "react-icons/hi2";
import { MdOutlineFastfood, MdOutlineFoodBank } from "react-icons/md";
import { RiBuilding2Fill } from "react-icons/ri";

export const getCommonFacility = (res) => {
  switch (res.name) {
    case "auditorium":
      return (
        <li key={res._id}>
          <HiBuildingStorefront /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "food court":
      return (
        <li key={res._id}>
          <MdOutlineFoodBank /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "gym":
      return (
        <li key={res._id}>
          <CgGym /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "indoor games":
      return (
        <li key={res._id}>
          <CgGames /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "yoga center":
      return (
        <li key={res._id}>
          <GrYoga /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "outdoor gardening and landscape":
      return (
        <li key={res._id}>
          <GiSparkPlug /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "pantry":
      return (
        <li key={res._id}>
          <MdOutlineFastfood /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "server room":
      return (
        <li key={res._id}>
          <FaServer /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "plug & play office space suits for it / ites companies":
      return (
        <li key={res._id}>
          <RiBuilding2Fill /> <span>{capitalize(res.name)}</span>
        </li>
      );
    default:
      return null;
  }
};
