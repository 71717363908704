import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { BrowserRouter, Link } from "react-router-dom";
import { ErrorBoundary } from "./components";
import OfflinePage from "./pages/OfflinePage";
import { useNavigatorOnLine } from "./hooks";
import { store } from "./store";
import Router from "./routes/Router";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { FaWhatsapp } from "react-icons/fa";

function AppRender() {
  const networkStatus = useNavigatorOnLine();
  return (
    <ErrorBoundary>
      <Provider store={store}>
        {networkStatus ? (
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Header />
            <Router />
            <div className="whatsapp">
              <Link
                to="https://api.whatsapp.com/send?phone=+919171766660&text=Hi%20Chennai%20Commercials"
                target="_blank"
              >
                <FaWhatsapp />
              </Link>
            </div>
            <Footer />
          </BrowserRouter>
        ) : (
          <OfflinePage />
        )}
      </Provider>
    </ErrorBoundary>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppRender />);
