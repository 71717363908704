import React from "react";
import { Link } from "react-router-dom";

// icons
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookMessenger,
} from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";

// Bootstarp
import Container from "react-bootstrap/Container";

// sass
import "./Footer.scss";

// data
// import { headerDatas } from '../data/DataHome'

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <Container>
          <div className="footer__content d-flex py-5">
            <div className="contact">
              <h4>Contact Us</h4>
              <p>
                Chennai commercials is an exclusive real estate concern for
                commercial property dealings in Chennai.
              </p>
              <div className="d-flex location">
                <MdLocationOn />
                <p>
                  64 , Hari building , 1st floor , South Usman Road , Opp To
                  Aruna Hotel , T Nagar , Chennai , Tamil Nadu 600017{" "}
                </p>
              </div>
              <div className="d-flex phoneNumber">
                <BsFillTelephoneFill /> <Link to="tel:+0917-176-6660">091717 66660</Link>,<Link to="tel:044-491-2646">044 491 2646</Link> 
              </div>
              <div className="d-flex envelope">
                {" "}
                <BsFillEnvelopeFill />{" "}
                <Link to="mailto:chennaicommercials@gmail.com">
                  chennaicommercials@gmail.com
                </Link>
              </div>
            </div>
            <div className="popular-location d-flex">
              <h4>Popular Location</h4>
              <a href="/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">Office For Rent In Chennai</a>
              <a href="/filter2?workspaceType=office&propertyType=Sale&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">Office For Sale In Chennai</a>
              <a href="/filter2?workspaceType=warehouse&propertyType=Rent&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">Warehouse For Rent In Chennai</a>
              <a href="/filter2?workspaceType=warehouse&propertyType=Sale&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">Warehouse For Sale In Chennai</a>
              <a href="/filter2?workspaceType=office&propertyType=Rent&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">Office For Rent In IT Park Chennai</a>
              <a href="/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">100 Seater Office For Rent In Chennai</a>
              <a href="/filter2?workspaceType=office&type=Rent&location=undefined&district=Chennai&totalArea=undefined&rentalValue=undefined" target="_blank">200 Seater Office For Rent In Chennai</a>

            </div>
            {/* <div className="popular-location d-flex">
                            <h4>Popular Locations</h4>
                            {
                                headerDatas.map((headerData) => {
                                    return (

                                        <Link key={headerData.id} to={headerData.path}>{headerData.navDropDownLInk}</Link>
                                    )
                                })
                            }

                        </div> */}
            <div className="quick-links d-flex">
              <h4>Quick Links</h4>
              <Link to="/about">About</Link>
              <Link to="/">Blog</Link>
              <Link to="/">Sell Your Office</Link>
              <Link to="/filter2?workspaceType=office&propertyType=Rent&location=undefined&totalArea=undefined&rentalValue=undefined">Rent Office</Link>
              <Link to="/filter2?workspaceType=office&propertyType=undefined&location=undefined&district=undefined&totalArea=undefined&rentalValue=undefined">Open Office</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
            <div className="follow-us">
              <h4>Follow Us</h4>
              <div className="followUs-icons d-flex">
                <Link to="https://www.facebook.com/chennaiofficespace" target="_blank">
                <FaFacebookF />
                </Link>
                <Link to="https://twitter.com/login" target="_blank">
                <FaTwitter />
                </Link>
                <Link to="https://in.linkedin.com/" target="_blank">
                <FaLinkedinIn />
                </Link>
                <Link to="https://www.facebook.com/messenger" target="_blank">
                <FaFacebookMessenger />
                </Link>
                
                {/* <FaFacebookF onClick={()=> window.location.href = "https://www.facebook.com/chennaiofficespace" } /> */}
                {/* <FaTwitter onClick={()=> window.location.href = "https://twitter.com/login" } />
                <FaLinkedinIn onClick={()=> window.location.href = "https://in.linkedin.com/" } />
                <FaFacebookMessenger onClick={()=> window.location.href = "https://www.facebook.com/messenger/" } /> */}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p className="text-center">
              ©2023 All Rights Reserved. Design and Developed by{" "}
              <Link to="https://www.webdads2u.com/" target="_blank">WEBDADS2U PVT LTD</Link>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
