import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {Select, Slider, Input} from 'antd';
import { capitalize } from "lodash";
import { MdLocationOn } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";

// sass
import './ThankYou.scss'


// router dom
import { useNavigate } from 'react-router-dom'

// Bootstarp
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Loader from "./../../components/Loader/Loader";
import Quote from '../../assets/images/quote.jpg'


// social icons
import { FiFacebook, FiTwitter } from 'react-icons/fi'
import { BsEnvelope } from 'react-icons/bs'
import { ImPinterest2 } from 'react-icons/im'
import { setConstant, setLocalities } from '../../store/appSlice';
import { getDropdownConstant, getLocalities } from '../../store/properties';
import { postQuoteDetail } from '../../store/quote';
import { postMoreDetail } from '../../store/contact';

let initialValues = {
  name: null,
  email: null,
  mobile: null,
  message: null,
  price: [20,50],
  location: null,
};

const mobileRegExp = /^[0-9]{10}$/;
const nameRegExp = /^[A-Za-z\s]*$/;
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(nameRegExp, "Invalid characters in name")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Required !"),
  email: Yup.string().matches(emailRegExp, "Invalid email format!").required("Required !"),
  mobile: Yup.string()
    .matches(mobileRegExp, "Mobile number is not valid")
    .required("Required !"),
  message: Yup.string().required('Message cannot be null'),
  location: Yup.string().required("Required !")
});

const ThankYou = () => {
  const dispatch = useDispatch()
  const {localities} = useSelector(state => state.app)

  const [loader, setLoader] = useState(false);
  const [emailStatus, setEmailStatus] = useState({email: "", placeholder: "Email here", status: false});

  const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  const onSubmit = (values) => {
    setLoader(true);
    console.log(values,"=> request form quote")
    if (values) {
      const quoteData = {
        name: values?.name,
        email: values?.email,
        mobile: values?.mobile,
        message: values?.message,
        location: values?.location,
        priceStarts: values?.price[0] * 1000,
        priceEnds: values?.price[1] * 1000
      }
      dispatch(postQuoteDetail(quoteData)).then(res => console.log(res, 'quote res'))
      setLoader(false);
    }
  };

  useEffect(()=>{
     dispatch(getLocalities()).then(res => {
      dispatch(setLocalities(res[0]?.data))
     })

  },[])

  const handleEmailNotification = () => {
    if(emailStatus?.email !== ""){
      if(emailReg.test(emailStatus?.email) === true){
        setEmailStatus({email: "", placeholder: "Email here", status : ""});
        dispatch(postMoreDetail({"isNewsLetter": true,"email": emailStatus?.email})).then((res) => {
          console.log("res ", res);
        });
      }
      else setEmailStatus({email:"",placeholder: 'Invalid Email', status: true})
    }
    else setEmailStatus({email:"",placeholder: 'Email Required', status: true}) 
    };

    const navigate = useNavigate()
    return (
        <>
            <div className="thank-wrapper">
            {!loader ? 
            <>
            <Container className='thank d-flex'>
                    <div className="innerPage__form">
                      <h2>Get a Free Quotes</h2>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({setFieldValue, values }) => (
                        <Form className='form'>
                          <div className="form-control">
                            <label htmlFor="name">
                              Name <span>*</span>
                            </label>
                            <Field type="text" name="name" id="name" />
                            <ErrorMessage name="name">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="email">
                              Email <span>*</span>{" "}
                            </label>
                            <Field type="email" name="email" id="email" />
                            <ErrorMessage name="email">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="mobile">
                              Mobile <span>*</span>{" "}
                            </label>
                            <Field name="mobile" id="mobile" />
                            <ErrorMessage name="mobile">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="location">
                              Location <span>*</span>{" "}
                            </label>
                            <Field name="location" as="select"
                            render={({field})=>(
                              <Select
                              style={{
                                width: '100%',
                              }}
                              options={localities?.map(y=> ({label: `${capitalize(y?.locationName)}, ${capitalize(y?.district)}`, value: capitalize(y?.locationName)}))}
                              onChange={(val) => setFieldValue("location", val)}
                              showSearch
                              allowClear
                              />
                            )}                            
                            />
                            <ErrorMessage name="location">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="form-control">
                            <label htmlFor="Price">
                              Price Range <span>{`[₹${values.price[0]*1000} - ₹${values.price[1]*1000}]`}</span>{" "}
                            </label>
                            <Field 
                            name="price"
                            render ={({field})=> (
                            <Slider
                             range
                             defaultValue={[20,50]}
                             onChange={(val)=> setFieldValue("price",val)}
                             min={1}
                            />
                            )}
                            />
                            
                          </div>

                          <div className="form-control">
                            <label htmlFor="message">
                              Message <span>*</span>{" "}
                            </label>
                            <Field id="message" name="message" as="textarea" />
                            <ErrorMessage name="message">
                              {(msg) => (
                                <div className="error-message">{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="from-control">
                            <button type="submit" onSubmit={onSubmit}>
                              submit
                            </button>
                          </div>
                        </Form>
                        )}
                      </Formik>
                      </div>

                      <div className='quote-img'>
                        <img src={Quote} alt="quote" />
                      </div>
                    {/* <div className="imgDiv">
                        <img src={thankGif} alt="thankGif" />
                        <h2>Thank You...</h2>
                    </div>
                    <div className="contact py-2">
                        <strong className=''>Mobile Number :</strong>
                        <span> 091717 66660</span>
                    </div>
                    <div className="social-icons d-flex justify-content-between">
                        <button><FiFacebook /> </button>
                        <button><FiTwitter /> </button>
                        <button><ImPinterest2 /></button>
                        <button><BsEnvelope /></button>
                    </div>
                    <div className="back">
                        <Button variant="outline-warning" onClick={() => navigate(-1)}>Go back</Button>
                    </div> */}

                  
            </Container>

                <div className="contact-bottom-wrapper">
                <Container>
                  <div className="contact-bottom">
                    <div className="title">
                    <h2>Quick Support</h2>
                    <p>{capitalize("you can get all the contact information")}</p>
                    </div>
                    <div className="details d-flex">
                      <div className="details__content">
                        <p>
                          <MdLocationOn />
                        </p>
                        <h5>Visit Us</h5>
                        <small>
                          64, Hari building, 1st floor, South Usman Road, T Nagar
                        </small>
                        <br />
                        <small>Chennai,Tamil Nadu 600017</small>
                      </div>
                      <div className="details__content">
                        <p>
                          <BsFillTelephoneFill />
                        </p>
                        <h5>Call us </h5>
                        <small>091717 66660</small> <br />
                        <small>044 491 2646</small>
                      </div>
                      <div className="details__content">
                        <p>
                          <BsFillEnvelopeFill />
                        </p>
                        <h5>Email Us</h5>
                        <small>chennaicommercials@gmail.com</small>
                      </div>
                    </div>
                  </div>
                </Container>
                </div>

                {/* More Details */}
                <div className="more-details">
                    <div className="content">
                    <p>More Details For Properties</p>
                    <div className="form">
                    <Input value={emailStatus?.email} placeholder={emailStatus?.placeholder} style={{borderColor: emailStatus?.status ? '#ff4d4f' : ''}} bordered={emailStatus?.status ? true: false} onChange={(e)=> setEmailStatus({email: e?.target?.value, status: "", placeholder: 'Email here'})} /> 
                    <button type="submit" className="submit" onClick={handleEmailNotification}>
                      Submit
                    </button>
                    </div>
                    </div>
                  </div>
                {/* More Details */}
                </>
               
                  :
                <>
                <Loader />
              </>}
            </div>
        </>
    )
}

export default ThankYou