import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// pages
import WarhouseRentChennai from "./../pages/WarhouseRentChennai";
import ItParksChennai from "./../pages/ItParksChennai";
import Propertiessale from "./../pages/Propertiessale";
import Contact from "./../pages/contact/Contact";
import Enquiry from "./../pages/Enquiry";

import About from "./../pages/about/About";
import Description from "./../pages/descriptionPage/Description";
import ThankYou from "./../pages/thankYouPage/ThankYou";
import Filter2 from "./../pages/fiter2/Filter2";
import NotFound from "./../pages/OfflinePage";
import FakeApi from "./../components/fakeApi/FakeApi";
import Loader from "./../components/Loader/Loader";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import View from "../pages/views/View";

export default function Router() {
  const LazyHome = React.lazy(() => import("./../pages/home/Home"));
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <LazyHome />
          </Suspense>
        }
      />
      <Route
        path="/it-parks-chennai"
        element={
          <Suspense fallback={<Loader />}>
            <ItParksChennai />
          </Suspense>
        }
      />
      <Route
        path="/properties-for-sale"
        element={
          <Suspense fallback={<Loader />}>
            <Propertiessale />
          </Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <Suspense fallback={<Loader />}>
            <Contact />
          </Suspense>
        }
      />
      <Route
        path="/enquiery"
        element={
          <Suspense fallback={<Loader />}>
            <Enquiry />
          </Suspense>
        }
      />
      <Route
        path="/warhouse-rent-chennai"
        element={
          <Suspense fallback={<Loader />}>
            <WarhouseRentChennai />
          </Suspense>
        }
      />
      <Route
        path="/about"
        element={
          <Suspense fallback={<Loader />}>
            <About />
          </Suspense>
        }
      />
      {/* inner pages */}
      <Route
        path="/description/:id"
        element={
          <Suspense fallback={<Loader />}>
            <Description />
          </Suspense>
        }
      />
      <Route
        path="/view/:district/:location/:state/:name"
        element={
          <Suspense fallback={<Loader />}>
            <View />
          </Suspense>
        }
      />
      {/* <Route path="/description/:id" element={<Description />}/> */}
      {/* <Route path="/description" element={<Apidesc />} /> */}
      <Route
        path="thankyou"
        element={
          <Suspense fallback={<Loader />}>
            <ThankYou />
          </Suspense>
        }
      />
      <Route
        path="filter2"
        element={
          <Suspense fallback={<Loader />}>
            <Filter2 />
          </Suspense>
        }
      />
      <Route
        path="api"
        element={
          <Suspense fallback={<Loader />}>
            <FakeApi />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<Loader />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
}
