import { capitalize } from "@mui/material";
import { FiServer } from "react-icons/fi";
import { GiDiscussion } from "react-icons/gi";
import { GrWorkshop } from "react-icons/gr";
import {
  MdOutlineFastfood,
  MdOutlineManageAccounts,
  MdOutlineMeetingRoom,
} from "react-icons/md";
import { SiGoogleclassroom, SiUps } from "react-icons/si";

export const getInteriorFacility = (res) => {
  switch (res.name) {
    case "reception":
      return (
        <li key={res._id}>
          <GiDiscussion /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "meeting room":
      return (
        <li key={res._id}>
          <MdOutlineMeetingRoom /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "conference room":
      return (
        <li key={res._id}>
          <SiGoogleclassroom /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "manager cabin":
      return (
        <li key={res._id}>
          <MdOutlineManageAccounts /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "modular workstations":
      return (
        <li key={res._id}>
          <GrWorkshop /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "ups room":
      return (
        <li key={res._id}>
          <SiUps /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "server room":
      return (
        <li key={res._id}>
          <FiServer /> <span>{capitalize(res.name)}</span>
        </li>
      );
    case "inner pantry":
      return (
        <li key={res._id}>
          <MdOutlineFastfood /> <span>{capitalize(res.name)}</span>
        </li>
      );
      case "food court":
      return (
        <li key={res._id}>
          <MdOutlineFastfood /> <span>{capitalize(res.name)}</span>
        </li>
      );
      case "centralized ac":
        return (
          <li key={res._id}>
            <FiServer /> <span>{capitalize(res.name)}</span>
          </li>
        );
        case "gym":
      return (
        <li key={res._id}>
          <SiUps /> <span>{capitalize(res.name)}</span>
        </li>
      );
      case "lobby":
      return (
        <li key={res._id}>
          <SiUps /> <span>{capitalize(res.name)}</span>
        </li>
      );
      case "24 / 7 hrs security":
      return (
        <li key={res._id}>
          <SiUps /> <span>{capitalize(res.name)}</span>
        </li>
      );
      case "atrium":
        return (
          <li key={res._id}>
            <SiUps /> <span>{capitalize(res.name)}</span>
          </li>
        );
    default:
      return null;
  }
};
