import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { capitalize, sortBy } from "lodash";
import Loader from "../../components/Loader/Loader";
import { Select, Slider } from "antd";
// sass
import "./Filter2.scss";
// Bootstarp
import Container from "react-bootstrap/Container";
import Pagination from "react-bootstrap/Pagination";

// icon
import { BsFillGridFill, BsGrid3X3GapFill } from "react-icons/bs";
import { GoLocation } from "react-icons/go";

// pagination
import ReactPaginate from "react-paginate";
import {
  getDropdownConstant,
  getLocalities,
  getPropertiesLists,
} from "../../store/properties";
import removeSpace from "../../components/common/removeSpace";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setConstant, setLocalities } from "../../store/appSlice";
import { budgetOptions, sqftOptions } from "../../components/data/DataHome";

const imgURL = process.env.REACT_APP_IMAGE_URL;

const Filter2 = () => {
  const { constant, localities } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [card2, setCard2] = useState("card");
  const [feature, setFeature] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [filterDetails, setFilterDetails] = useState({
    listPriceFrom: 200000,
    listPriceTo: 500000,
  });
  const [loading, setLoading] = useState(false);
  const [stateValue, setStateValue] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const recentSearch = { data: [], index: [] };
  const randomItem = { data: [], index: [] };
  const API_URL = `${process.env.REACT_APP_API_URL}/properties`;
  let getStoreFilter = JSON.parse(localStorage.getItem("filterProperty"));
  const params = [];

  const getRandomData = (arr, items) => {
    const { data = [], index = [] } = arr;
    for (let i = 1; i <= 3; i++) {
      if (data?.length < 3) {
        const randomIndex = Math.floor(Math.random() * items.length);
        if (index.includes(randomIndex)) {
          index.push(Math.floor(Math.random() * items.length));
          data.push(items[Math.floor(Math.random() * items.length)]);
        } else {
          index.push(randomIndex);
          data.push(items[randomIndex]);
        }
      }
    }
  };

  const setPropertiesResponse = (data, meta) => {
    setCardData({ data, meta });
    setFilterData(data);
    if (data.length > 3) {
      getRandomData(randomItem, data);
      setFeature(randomItem?.data);
    } else setFeature(data);
  };

  useEffect(() => {
    let updateParams = [];
    searchParams.forEach((value, key) => {
      if (value === "undefined") params[key] = undefined; 
      else  params[key] = value
      if(key === 'totalArea'){
        const filterArea = sqftOptions?.find(x=> x?.value === value)
        updateParams["totalAreaStart"] = filterArea?.min
        updateParams["totalAreaEnd"] = filterArea?.max
      }
      if(key === 'rentalValue'){
        const filterBudget = budgetOptions?.find(x=> x?.value === value)
        updateParams["budgetStart"] = filterBudget?.min
        updateParams["budgetEnd"] = filterBudget?.max
      }

      updateParams = {...updateParams, ...params}
 
      delete updateParams["totalArea"]
      delete updateParams["rentalValue"]
      
    });
    setStateValue(params);
    console.log(params,'params')
   
    setLoading(true);
    dispatch(
      Object.keys(updateParams).length > 0
        ? getPropertiesLists(updateParams)
        : getPropertiesLists()
    ).then((res) => {
      if(res){
        const { data, meta } = res[0];
      setPropertiesResponse(data, meta);
      }
      setLoading(false);    
    });
    if (getStoreFilter?.length > 3) {
      getRandomData(recentSearch, getStoreFilter);
      setRecentData(recentSearch?.data);
    } else setRecentData(getStoreFilter);
    //  console.log(recentSearch,randomItem,'recent search')
  }, [searchParams]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    axios
      .get(`${API_URL}`, { cancelToken: cancelToken.token })
      .then((responce) => {
        const data = responce.data?.data ? responce.data?.data : null;
        setPosts(data);
      })
      .catch((error) => {
        console.warn(error);
      });

    dispatch(getDropdownConstant()).then((res) => {
      dispatch(setConstant(res[0]?.data));
    });
    dispatch(getLocalities()).then((res) => {
      dispatch(setLocalities(res[0]?.data));
    });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  let pageCount = cardData?.meta?.numberOfPages;
  let pageSize = cardData?.meta?.pageSize;
  let endOffset = itemOffset + pageSize;
  let currentItems = cardData?.data?.slice(itemOffset, endOffset);

  const reset = () => {
    return setCard2("card");
  };

  const card2Click = () => {
    return setCard2("card2");
  };

  const sortSelect = (e) => {
    const sortValue = e.target.value;
    let sortData;
    if (sortValue === "price")
      sortData = currentItems?.sort(
        (a, b) =>
          a?.rentalValue &&
          b?.rentalValueNumber &&
          Number(a?.rentalValue.split(",").join("")) -
            Number(b?.rentalValue.split(",").join(""))
      );
    if (sortValue === "date") sortData = sortBy(currentItems, "createdAt");
    setFilterData(sortData);
  };

  const storeSearch = (filterArr) => {
    if (getStoreFilter?.length > 0) {
      const mergeLocal = [...getStoreFilter, ...filterArr];
      const uniq = [
        ...new Map(mergeLocal?.map((item) => [item["_id"], item])).values(),
      ];
      localStorage.setItem("filterProperty", JSON.stringify(uniq));
    } else localStorage.setItem("filterProperty", JSON.stringify(filterArr));
  };

  const formSubmit = (e) => {    
    e.preventDefault();
    let { workspaceType, propertyType, location, district, totalArea, rentalValue } = stateValue;
    setSearchParams({ workspaceType, propertyType, location, district, totalArea, rentalValue });
    let updatePayload = {workspaceType, propertyType, location, district}
    if(totalArea){
      const filterArea = sqftOptions?.find(x=> x?.value === totalArea)
      Object.assign(...updatePayload, {"totalAreaStart" : filterArea?.min, "totalAreaEnd":filterArea?.max})
    }
    else if(rentalValue){
      const filterBudget = budgetOptions?.find(x=> x?.value === rentalValue)
      Object.assign(...updatePayload, {"budgetStart" : filterBudget?.min, "budgetEnd":filterBudget?.max})
    }

    setLoading(true);
    // dispatch(queryState ? getParamPropertiesLists(workspaceType ,propertyType ,location):getPropertiesLists()).then(res=> {
    dispatch(
      Object.keys(updatePayload).length > 0
        ? getPropertiesLists(updatePayload)
        : getPropertiesLists()
    ).then((res) => {
      if(res){
        const { data, meta } = res[0];
      setPropertiesResponse(data, meta);
      }
      setLoading(false);
    });

    if (getStoreFilter?.length > 3) {
      getRandomData(recentSearch, getStoreFilter);
      setRecentData(recentSearch?.data);
    } else setRecentData(getStoreFilter);
  };

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected: selectedPage }) => {
    const newOffset = (selectedPage * pageSize) % cardData?.data.length;
    setItemOffset(newOffset);
    dispatch(
      getPropertiesLists({
        ...stateValue,
        page: selectedPage + 1,
        pageSize: pageSize,
      })
    ).then((res) => {
      setCardData({ ...cardData, data: res[0].data });
      currentItems = res[0]?.data?.slice(itemOffset, endOffset);
      setFilterData(currentItems);
      setFilterDetails({
        listKey: "",
        listPriceFrom: "",
        listPriceTo: "",
        listType: "",
      });
    });
  };

  const listSubmit = (e) => {
    e.preventDefault();
    const { listKey, listType, listPriceFrom, listPriceTo } = filterDetails;
    let listFilter;
    if (listType)
      listFilter = currentItems?.filter((v) => v?.propertyType === listType);
    if (listKey) {
      if (listFilter?.length > 0)
        listFilter = listFilter?.filter((b) =>
          b?.workspaceType.toLowerCase().includes(listKey.toLowerCase())
        );
      else
        listFilter = currentItems?.filter((b) =>
          b?.workspaceType.toLowerCase().includes(listKey.toLowerCase())
        );
    }
    if (listPriceFrom) {
      if (listFilter?.length > 0)
        listFilter = listFilter?.filter(
          (x) =>
            x?.rentalValue &&
            Number(x?.rentalValue.split(",").join("")) >= listPriceFrom
        );
      // listFilter = listFilter?.filter(x=> x?.rentalValue && Number(x?.rentalValue.split(",").join("")) >= Number(listPriceFrom?.split(",").join('')))
      else
        listFilter = currentItems?.filter(
          (x) =>
            x?.rentalValue &&
            Number(x?.rentalValue.split(",").join("")) >= listPriceFrom
        );
      // listFilter = currentItems?.filter(x=> x?.rentalValue && Number(x?.rentalValue.split(",").join("")) >= Number(listPriceFrom?.split(",").join('')))
    }
    if (listPriceTo) {
      if (listFilter?.length > 0)
        listFilter = listFilter?.filter(
          (x) =>
            x?.rentalValue &&
            Number(x?.rentalValue.split(",").join("")) <= listPriceTo
        );
      // listFilter = listFilter?.filter(c=>  c?.rentalValue &&  Number(c?.rentalValue.split(",").join("")) <= Number(listPriceTo?.split(",").join('')))
      else
        listFilter = currentItems?.filter(
          (x) =>
            x?.rentalValue &&
            Number(x?.rentalValue.split(",").join("")) <= listPriceTo
        );
      // listFilter = currentItems?.filter(c=>  c?.rentalValue &&  Number(c?.rentalValue.split(",").join("")) <= Number(listPriceTo?.split(",").join('')))
    }

    listFilter?.length > 0
      ? setFilterData(listFilter)
      : setFilterData(cardData?.data);

    storeSearch(listFilter);

    if (getStoreFilter?.length > 3) {
      getRandomData(recentSearch, getStoreFilter);
      setRecentData(recentSearch?.data);
    } else setRecentData(getStoreFilter);
  };

  // console.log(getStoreFilter,'getStoreFilter')
  // console.log(filterDetails, "filterDetails");
  // console.log(recentData, 'recentData')
  // console.log(currentItems,'currentItems')
  // console.log(stateValue, "stateValue");

  return (
    <>
      <div className="filter2-wrapper">
        <div className="filter2 py-3">
          <Container>
            <div className="filter2__searchSection py-4">
              <form className="form">
                <Select
                  value={
                    stateValue?.workspaceType
                      ? stateValue?.workspaceType
                      : "Property Type"
                  }
                  options={constant?.workspaceTypes}
                  style={{
                    width: 250,
                    textAlign: "left"
                  }}
                  onChange={(e) =>
                    setStateValue({
                      ...stateValue,
                      workspaceType: e || undefined,
                    })
                  }
                  showSearch
                  allowClear
                />

              <Select
                value={stateValue?.totalArea ? stateValue?.totalArea : "Sqft"}
                options={sqftOptions}
                style={{
                  width: 250,
                  textAlign: "left"
                }}
                onChange={(e) =>
                  setStateValue({ ...stateValue, totalArea: e || undefined})
                }
                showSearch
                allowClear
              />
              <Select
                value={stateValue?.rentalValue ? stateValue?.rentalValue : "Budget"}
                options={budgetOptions}
                style={{
                  width: 250,
                  textAlign: "left"
                }}
                onChange={(e) =>
                  setStateValue({ ...stateValue, rentalValue: e || undefined})
                }
                showSearch
                allowClear
              />
                {/* <select aria-label="Default select example" id="propType" value={ stateValue?.workspaceType ? stateValue?.workspaceType : ""} onChange={(e)=>
                  setStateValue({...stateValue, workspaceType :e?.target?.value || undefined})
                }>
                  <option value="">Property type</option>
                  {constant?.workspaceTypes?.map((salePropertie) => {
                    return (
                      <option
                        key={salePropertie.value}
                        value={salePropertie.value}
                      >
                        {salePropertie.label}
                      </option>
                    );
                  })}
                </select> */}

                <Select
                  value={
                    stateValue?.propertyType ? stateValue?.propertyType : "Type"
                  }
                  options={constant?.propertyTypes?.map((y) => ({
                    label: y?.value,
                    value: y?.value,
                  }))}
                  style={{
                    width: 250,
                    textAlign: "left"
                  }}
                  onChange={(e) =>
                    setStateValue({
                      ...stateValue,
                      propertyType: e || undefined,
                    })
                  }
                  showSearch
                  allowClear
                />

                {/* <select aria-label="Default select example" id="type" value={ stateValue?.type ? stateValue?.type : ""} onChange={(e)=>
                  setStateValue({...stateValue, type :e?.target?.value || undefined})
                } >
                  <option value="">Type</option>
                  {constant?.propertyTypes?.map((post) => {
                    return (
                      <option
                        key={post.id}
                        value={post.value}
                      >
                        {post.value}
                      </option>
                    );
                  })}
                </select> */}

                <Select
                  value={
                    stateValue?.location ? stateValue?.location : "Location"
                  }
                  options={localities?.map((y) => ({
                    label: `${capitalize(y?.locationName)}, ${capitalize(
                      y?.district
                    )}`,
                    value: capitalize(y?.locationName),
                  }))}
                  style={{
                    width: 250,
                    textAlign: "left"
                  }}
                  onChange={(e) =>
                    setStateValue({ ...stateValue, location: e || undefined })
                  }
                  showSearch
                  allowClear
                />

                {/* <select aria-label="Default select example" id="propLoc" value={stateValue?.location ? stateValue?.location : ""} onChange={(e)=>
                  setStateValue({...stateValue, location :e?.target?.value || undefined})
                }>
                  <option value="">Location</option>
                  {localities?.map((post) => {
                    return (
                      <option
                        key={post._id}
                        value={capitalize(post?.locationName)}
                      >
                        {`${capitalize(post?.locationName)}, ${capitalize(post?.district)}`}
                      </option>
                    );
                  })}
                </select> */}

                <button type="submit" className="submit" onClick={formSubmit}>
                  {" "}
                  Submit
                </button>
              </form>
            </div>
          </Container>
          {loading ? (
            <Loader />
          ) : (
            <div className="filter2__mainSection-wrapper">
              <Container>
                <div className="mainSection d-flex py-4">
                  <div className="mainSection__left">
                    <div className="result__title pt-2">
                      <p>
                        Showing all Properties listings tagged "Good condition"
                      </p>
                    </div>
                    <hr />
                    <div className="resultSort d-flex py-4">
                      <div className="resultSort__left">
                        <p>{filterData?.length} Results found</p>
                      </div>
                      <div className="resultSort__right d-flex">
                        <p>sort by :</p>
                        <select
                          aria-label="Default select example"
                          className="sort"
                          id="sort"
                          onChange={sortSelect}
                        >
                          <option value="">Select</option>
                          <option value="date">Date</option>
                          <option value="price">Price</option>
                          <option value="sqft">Sqft</option>
                          <option value="land">Land</option>
                        </select>
                        <div className="icons d-flex">
                          <i onClick={reset}>
                            <BsFillGridFill />
                          </i>
                          <i onClick={card2Click}>
                            <BsGrid3X3GapFill />
                          </i>
                        </div>
                      </div>
                    </div>
                    <div className={` ${card2} d-grid`}>
                      {filterData?.map((cardData) => {
                        const district = capitalize(
                          cardData?.locality?.district
                        );
                        const locName = capitalize(
                          cardData?.locality?.locationName
                        );
                        return (
                          <Link
                            to={`/view/${removeSpace(
                              cardData?.locality?.district
                            )}/${removeSpace(
                              cardData?.locality?.locationName
                            )}/${removeSpace(
                              cardData?.locality?.state
                            )}/${removeSpace(cardData?.urlSlug)}`}
                          >
                            <div className="imgDiv" key={cardData._id}>
                              <div className="imgDiv__top">
                                <img
                                  src={`${imgURL}${cardData?.images[0]}`}
                                  alt={cardData?.title}
                                />
                              </div>
                              <div className="imgDiv__bottom py-2">
                                <p>{capitalize(cardData?.title)}</p>
                                <p>
                                  <strong>Price : </strong>{" "}
                                  {cardData?.rentalValue}
                                </p>
                                <small>
                                  <GoLocation /> {locName}, {district}
                                </small>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>

                    <div className="pagination d-flex justify-content-center mt-4">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                  <div className="mainSection__right">
                    <div className="filterForm mb-5">
                      <div className="title">
                        <h4>Filter Listing</h4>
                      </div>
                      <form action="">
                        <div className="listingType py-2">
                          <label htmlFor="">Listing type</label>
                          <Select
                            value={
                              filterDetails?.listType
                                ? filterDetails?.listType
                                : "Type"
                            }
                            options={constant?.propertyTypes?.map((y) => ({
                              label: y?.value,
                              value: y?.value,
                            }))}
                            style={{
                              width: 250,
                            }}
                            onChange={(e) =>
                              setFilterDetails({
                                ...filterDetails,
                                listType: e,
                              })
                            }
                            showSearch
                            allowClear
                          />
                          {/* <select name="" id="listType" placeholder="Type" value={filterDetails?.listType} onChange={(e)=> setFilterDetails({...filterDetails,listType: e?.target?.value})}>
                          <option value="">Type</option>
                          {constant?.propertyTypes?.map((post) => {
                            return (
                            <option
                            key={post.id}
                            value={post.value}
                            >
                            {post.value}
                            </option>
                            );
                            })}
                        </select> */}
                        </div>
                        <div className="keyWord py-2">
                          <label htmlFor="key">Keyword</label>
                          <input
                            type="text"
                            id="listKey"
                            value={filterDetails?.listKey}
                            onChange={(e) =>
                              setFilterDetails({
                                ...filterDetails,
                                listKey: e?.target?.value,
                              })
                            }
                          />
                        </div>
                        <label htmlFor="range">{`Price Range : ₹${filterDetails.listPriceFrom} - ₹${filterDetails.listPriceTo}`}</label>
                        <Slider
                          range
                          defaultValue={[20, 50]}
                          onChange={(val) =>
                            setFilterDetails({
                              ...filterDetails,
                              listPriceFrom: val[0] * 10000,
                              listPriceTo: val[1] * 10000,
                            })
                          }
                        />
                        <div className="price d-flex py-2">
                          {/* <div className="priceF" id="priceFrom">
                          <label htmlFor="priceFrom">Price From</label>
                          <input type="text" name="priceFrom" id="priceFrom" value={filterDetails?.listPriceFrom} onChange={(e)=> setFilterDetails({...filterDetails,listPriceFrom: e?.target?.value})} />
                        </div>
                        <div className="priceT">
                          <label htmlFor="priceTo">Price To</label>
                          <input type="text" name="priceTo" id="priceTo" value={filterDetails?.listPriceTo} onChange={(e)=> setFilterDetails({...filterDetails,listPriceTo: e?.target?.value})} />
                        </div> */}
                        </div>
                        <div className="filterButton py-2">
                          <button type="submit" onClick={listSubmit}>
                            Filter
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="featuredListing my-3">
                      <div className="title">
                        <h4>Featured Listing</h4>
                      </div>

                      {feature?.map((x) => {
                        return (
                          <div
                            className="featuredListing__card d-flex my-2"
                            key={x?._id}
                          >
                            <div className="imgDiv">
                              <img
                                src={`${imgURL}${x?.images[0]}`}
                                alt="location"
                              />
                            </div>
                            <div className="content">
                              <p>
                                {capitalize(x?.locality?.locationName)},{" "}
                                {capitalize(x?.locality?.district)}
                              </p>
                              <small>{x?.totalArea}</small>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="recentSearch my-3">
                      <div className="title">
                        <h4>Recent Search</h4>
                      </div>
                      {recentData?.map((d) => {
                        return (
                          <div
                            className="recentSearch__card d-flex my-2"
                            key={d?._id}
                          >
                            <div className="imgDiv">
                              <img
                                src={`${imgURL}${d?.images[0]}`}
                                alt="location"
                              />
                            </div>
                            <div className="content">
                              <p>
                                {capitalize(d?.locality?.locationName)},{" "}
                                {capitalize(d?.locality?.district)}
                              </p>
                              <small>{d?.totalArea}</small>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Filter2;
